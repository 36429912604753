import React from "react";
import { Reveal } from "src/components/global/reveal";

// Import component styles
import styles from './info-section.module.css';

export const InfoSection = ({ items }) => {
  return (
    <Reveal style="borderBottom">
      <div className={styles.info}>
      {items.map((col, colIndex) => (
        <div key={colIndex} className={styles.infoCol}>
          {col.map((item, itemIndex) => (
            <div key={itemIndex} className={styles.infoItem}>
              <h4 className={styles.infoItemTitle}>
                {item.title}
              </h4>
              <div className={styles.infoItemText}>
                {item.text.map((textPart, textIndex) => (
                  <React.Fragment key={textIndex}>
                    {textPart}
                  </React.Fragment>
                ))}
              </div>
              {item.disclaimer && (
                <p className={styles.infoItemDisclaimer}>
                  {item.disclaimer}
                </p>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
    </Reveal>
  );
};
