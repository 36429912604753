import React from "react";
import { Reveal } from "src/components/global/reveal";

// Import component styles
import styles from "./hero-section.module.css";

export const HeroSection = () => {
  return (
    <Reveal style="borderBottom">
      <div className={styles.hero}>
        <h1 className={styles.heroTitle}>CO-LIVING</h1>

        <p className={styles.heroLabel}>
          FULLY AMENITIZED AND FURNISHED PRIVATE ROOMS
        </p>

        <p className={styles.heroText}>
          Discover fully furnished private bedrooms, incredible amenities, and
          city living made easy with our thoughtfully designed suites that offer
          opportunities for both privacy and community.
        </p>
      </div>
    </Reveal>
  );
};
