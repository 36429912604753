import React from "react";
import { Reveal } from "src/components/global/reveal";

// Import component styles
import styles from "./hero-section.module.css";

export const HeroSectionThankyou = () => {
  return (
    <Reveal style="borderBottom">
      <div className={styles.hero}>
        <h1 className={styles.heroTitle}>CO-LIVING</h1>
        {/* 
        <p className={styles.heroLabel}>
          FULLY AMENITIZED AND FURNISHED PRIVATE ROOMS
        </p> */}

        <p className={`${styles.heroText} ${styles.coliving}`}>
          Thank you! We'll get back to you soon!
        </p>
      </div>
    </Reveal>
  );
};
