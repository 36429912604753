import React from "react";
import { Router } from "@reach/router";

import Coliving from "src/templates/coliving";
import { colivingPageContext } from 'src/templates/coliving-page-context';

const ColivingPage = ({
	location,
	pageContext,
}: {
	location: any;
	pageContext: {};
}) => {
	return (
		<Router primary={false}>
			<Coliving
				location={location}
				path="/coliving"
				pageContext={colivingPageContext}
			/>
		</Router>
	);
};

export default ColivingPage;