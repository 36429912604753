import React, { useState } from "react";
import { navigate } from "gatsby";
import queryString from "query-string";
import axios from "axios";
import cx from "classnames";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";

import * as styles from "./form.module.css";

const TextInput = ({
  children,
  additionalClass
}: {
  children: any;
  additionalClass?: string;
}) => {
  return (
    <div className={cx(styles.textInputWrapper, styles[additionalClass])}>
      <label className={styles.label}>{children}</label>
    </div>
  );
};

export const ColivingForm = () => {
  const { handleSubmit, register, control, setValue, reset } = useForm();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [inputType, setInputType] = useState<string>("text");

  const handleFocus = () => {
    setInputType("date");
    const defaultDate = new Date().toISOString().split("T")[0]; // Today's date in 'YYYY-MM-DD' format
    setValue("moveInDate", defaultDate);
  };

  const onSubmit = (data: any) => {
    const request = {
      formdata_name: `${data.firstName} ${data.lastName}`,
      formdata_email: data.email,
      formdata_phone: data.phone,
      formdata_comments: data.comments,
      formdata_move_in_date: data.moveInDate,
      formdata_source: sessionStorage.getItem("utm_source"),
      formdata_medium: sessionStorage.getItem("utm_medium"),
      formdata_campaign: sessionStorage.getItem("utm_campaign"),
      formdata_keywords: sessionStorage.getItem("utm_term"),
      formdata_property: "true"
    };

    axios
      .post(
        `https://nwgapi.com/apiforms/agnes/`,
        queryString.stringify(request, { sort: false })
      )
      .then(res => {
        console.log(res);
        setIsFormSubmitted(true); // Hide form and show thank you message
        reset();
        navigate("/coliving-thankyou");
      })
      .catch(error => {
        console.error("Form submission error:", error); // Handle any errors here
      });
  };

  return (
    <>
      {!isFormSubmitted ? (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formFieldsContainer}>
            <TextInput>
              <input
                className={cx("serif", styles.input)}
                name="firstName"
                type="text"
                required
                ref={register({
                  required: "Required"
                })}
                placeholder="First Name*"
              />
            </TextInput>
            <TextInput>
              <input
                className={cx("serif", styles.input)}
                name="lastName"
                type="text"
                required
                ref={register({
                  required: "Required"
                })}
                placeholder="Last Name*"
              />
            </TextInput>
            <TextInput>
              <input
                className={cx("serif", styles.input)}
                name="email"
                type="email"
                required
                ref={register({
                  required: "Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address"
                  }
                })}
                placeholder="Email*"
              />
            </TextInput>
            <TextInput>
              <Controller
                as={NumberFormat}
                type="tel"
                format="(###) ###-####"
                mask=""
                defaultValue=""
                name="phone"
                className={cx("serif", styles.input)}
                control={control}
                placeholder="Phone*"
                required
                ref={register({
                  required: "Required"
                })}
              />
            </TextInput>
            <TextInput additionalClass="date">
              <input
                className={cx("serif", styles.input)}
                name="moveInDate"
                type={inputType}
                onFocus={handleFocus}
                onBlur={() => setInputType("text")}
                placeholder="Move-in date*"
                required
                ref={register({
                  required: "Required"
                })}
              />
            </TextInput>
            <TextInput>
              <input
                className={cx("serif", styles.input)}
                name="comments"
                type="text"
                ref={register}
                placeholder="Comments"
              />
            </TextInput>
          </div>
          <button
            className={cx(styles.submitButton)}
            type="submit"
            aria-label="Submit"
          >
            Submit
          </button>
        </form>
      ) : (
        <div className={styles.thankYouMessage}>
          Thank you! We'll get back to you soon!
        </div>
      )}
    </>
  );
};
