import React, { useState, useEffect } from "react";
import { Element as ScrollElement } from "react-scroll";
import { useSetPage } from "src/context/siteContext";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { SEO } from "src/components/SEO";
import Image from "src/components/image";
import { ColivingMenu } from "src/components/global/coliving-menu";
import { OutlinedLabelGraphic } from "src/components/outlined-label-graphic";
import { InfoSection } from "src/components/coliving/info-section";
import { HeroSection } from "src/components/coliving/hero-section";
import { ApartmentsCarousel } from "src/components/apartments-carousel";
import { AmenityCarousel } from "src/components/amenity-carousel";
import { AdditionalAmenities } from "src/components/additional-amenities";
import { ColivingForm } from "src/components/coliving/form";
import { Reveal } from "src/components/global/reveal";

import { ReactComponent as BenefitsSVG } from "src/images/svg/benefits.svg";
import { ReactComponent as AmenitiesSVG } from "src/images/svg/amenities.svg";
import { ReactComponent as LifeSVG } from "src/images/svg/life.svg";
import { ReactComponent as AvailabilitySVG } from "src/images/svg/availability.svg";

import { ReactComponent as ViewMoreArrow } from "src/images/svg/more-arrow.svg";
import availabilityImage from "src/images/coliving/coliving-dining.jpg";

// Import component styles
import styles from "./coliving.module.css";

const benefitsContent = [
  [
    {
      title: "LUXURY FOR LESS",
      text: [
        "Enjoy a premier residential experience at a fraction of the cost. Our private furnished bedrooms provide an entry point to high-end living that is approximately 60% more cost-effective than traditional luxury apartments.",
      ],
      disclaimer:
        "Disclaimer: This estimate is based on current market data and may fluctuate over time; savings are not guaranteed but are reflective of our ongoing commitment to providing value.",
    },
    {
      title: "WEEKLY CLEANINGS",
      text: [
        "Your comfort and convenience are our top priority, which is why we provide weekly cleanings of shared spaces. This service maintains a pristine environment and allows you to enjoy your home without the burden of constant upkeep.",
      ],
    },
    {
      title: "MONTHLY SHARED GOODS",
      text: [
        "We understand the importance of having the essentials at hand, so we offer monthly shared goods as part of our commitment to making your life simpler and more convenient. ",
        <span style={{ fontWeight: 700 }}>
          For instance – no more splitting paper towel orders with your
          roommates!
        </span>,
        " This ensures you’re always stocked up on what you need and reduces the number of errands on your to-do list.",
      ],
    },
  ],
  [
    {
      title: "UTILITIES AND HOUSEHOLD AMENITIES",
      // text: [
      //   "Enjoy a worry-free living experience where all utilities are covered, including a generous $200 electricity stipend and high-speed WiFi. With essential services such as all kitchen supplies from pots/pans to silverware & more, included as part of the package, you can focus on enjoying your home without the hassle of additional bills.",
      // ],
      text: [
        "Enjoy a worry-free living experience where all utilities are covered, including a generous $200 electricity stipend and high-speed WiFi. Our kitchens come fully-stocked with all the essentials, from pots and pans to silverware and more.",
      ],
    },
    {
      title: "SHORT-TERM STAYS AVAILABLE",
      text: [
        "We offer flexible, short-term stay options for those interested in luxury living without an annual commitment. Whether you’re in town for a few months or just testing the waters, our short-term leases provide the perfect solution.",
      ],
    },
  ],
];
const lifeContent = [
  [
    {
      title: "CO-LIVING PERKS",
      text: [
        "Benefit from weekly cleanings, shared monthly goods, and flexible short-term stays with your utilities covered. There’s a lot to love about co-living! ",
        <a key="learn-more" href="#coliving-contact">
          Learn more
        </a>,
        ".",
      ],
    },
    {
      title: "THE NEIGHBORHOOD",
      text: [
        "Just across the river from NYC, Jersey City’s West Side offers renters access to the area’s historic and evolving culture. Enjoy expansive parks and delicious restaurants along with endless opportunities for entertainment.",
      ],
    },
    {
      title: "TRANSIT ACCESS",
      text: [
        "Your new home is conveniently located only 9 walking minutes from the West Side Avenue Light Rail and offers a free shuttle to the Journal Square PATH Station.",
      ],
    },
  ],
  [
    {
      title: "THE CITY",
      text: [
        "Often considered the 6th borough of New York City, Jersey City is bustling with up-and-coming restaurants, art galleries, theaters, and gorgeous greenery.",
      ],
    },
    {
      title: `COST-EFFECTIVE`,
      text: [
        "Monthly savings go beyond your rent, as included utilities reduce your costs in an area where studio prices are steep, and roomates provide the chance to pool other regular expenses.",
      ],
    },
  ],
];

interface ColivingProps {
  buildingSlug: string;
  pageContext: {
    main: {
      slug: {
        current: string;
      };
      title: string;
      entrataPropertyId: number;
      applicationUrl: string;
      unitCategories: [];
      promoBanner: {
        enableBanner?: boolean;
        headline?: string;
        finePrint?: string;
      };
      apartmentsCarousel: {
        title?: string;
        slides: [];
        verticalTitleSvg: {
          asset: {
            url: string;
          };
        };
      };
      amenitiesCarousels: {
        _key: string;
        title: string;
        graphic?:
          | {
              asset: {
                url: string;
              };
            }
          | undefined;
        slides: [];
      }[];
      additionalAmenities: [];
    };
    meta: {};
  };
  location: any;
  path: string;
}
const Coliving = ({ buildingSlug, pageContext, location }: ColivingProps) => {
  const [isAmenitiesVisible, setAmenitiesVisible] = useState(false);
  const setPage = useSetPage();

  useEffect(() => {
    setPage("coliving");
  }, [0]);

  const {
    amenitiesCarousels,
    additionalAmenities,
    apartmentsCarousel,
    applicationUrl,
    unitCategories,
    slug,
  } = pageContext.main;

  const toggleAmenitiesVisibility = () => {
    setAmenitiesVisible(!isAmenitiesVisible);
  };

  return (
    <RevealOnMount location={location}>
      <SEO pagePath={`/coliving`} metaInfo={{}} />

      <ColivingMenu
        buildingSlug={buildingSlug}
        applicationUrl={applicationUrl}
      />

      <HeroSection />

      <OutlinedLabelGraphic
        SVGComponent={BenefitsSVG}
        modifierClass={"benefits"}
      />

      <InfoSection items={benefitsContent} />

      <ApartmentsCarousel
        title={apartmentsCarousel.title}
        slides={apartmentsCarousel.slides}
        verticalTitleSvg={apartmentsCarousel.verticalTitleSvg}
        unitCategories={unitCategories}
        buildingSlug={slug.current}
      />

      <div className={styles.amenitiesSection}>
        <OutlinedLabelGraphic
          SVGComponent={AmenitiesSVG}
          modifierClass={"amenities"}
        />

        <button
          className={`${styles.amenitiesSectionButton} ${
            isAmenitiesVisible ? styles.active : ""
          }`}
          onClick={toggleAmenitiesVisibility}
        >
          View More
          <ViewMoreArrow className={styles.amenitiesSectionButtonArrow} />
        </button>
      </div>

      <div
        className={`${styles.amenitiesContent} ${
          isAmenitiesVisible ? styles.visible : styles.hidden
        }`}
      >
        {amenitiesCarousels.map((carousel, index: number) => (
          <ScrollElement name={encodeURI(carousel.title)} key={carousel._key}>
            <AmenityCarousel
              graphic={carousel.graphic}
              slides={carousel.slides}
              index={index}
            />
          </ScrollElement>
        ))}

        <AdditionalAmenities amenities={additionalAmenities} />
      </div>

      <OutlinedLabelGraphic
        SVGComponent={LifeSVG}
        modifierClass="life"
        decoration={`at the\nagnes`}
      />

      <InfoSection items={lifeContent} />

      <OutlinedLabelGraphic
        SVGComponent={AvailabilitySVG}
        modifierClass={"availability"}
      />

      <Reveal style="borderBottom">
        <div className={styles.availabilitySection} id="coliving-availability">
          <div className={styles.availabilitySectionCol}>
            <h3 className={styles.availabilitySectionTitle}>
              get more
              <br />
              for your rent
            </h3>

            <p className={styles.availabilitySectionText}>
              More connections, more experiences, more value, and supreme ease –
              discover a lifestyle designed to provide more of what you need and
              the sense of community you deserve. Explore available options
              today.
            </p>

            <a
              className={styles.availabilitySectionUrl}
              href={"../availability/the-agnes/co-living"}
            >
              CO-LIVING AVAILABILITY
            </a>
          </div>

          <div className={styles.availabilitySectionCol}>
            <div className={styles.availabilitySectionImageWrap}>
              <Image
                src={availabilityImage}
                className={styles.availabilitySectionImage}
              />
            </div>
          </div>
        </div>
      </Reveal>

      <div className={styles.contactSection} id="coliving-contact">
        <h3 className={styles.contactSectionTitle}>get in touch</h3>

        <p className={styles.contactSectionText}>
          Register below to learn more about opportunities to enjoy your own
          fully furnished bedroom at The Agnes.
        </p>

        <ColivingForm />
      </div>
    </RevealOnMount>
  );
};

export default Coliving;
